@import 'responsive';

$xl-screen: 1440px;
$xlg-screen: 1359px;
$lg-screen: 1200px;
$md-screen: 991px;
$sm-screen: 767px;
$xs-screen: 575px;

$max-width-layout-desktop: 1360px;
$min-width-layout-tablet: 768px;

$smallMobile: 'screen and (max-width: 320px)'; // iphone 5
$mobile: 'screen and (min-width: 321px) and (max-width: 375px)'; // iphone 6
$largeMobile: 'screen and (min-width: 376px) and (max-width: 568px)'; // iphone 6 plus
$smallTablet: 'screen and (min-width: 569px) and (max-width: 768px)';
$largeTablet: 'screen and (min-width: 769px) and (max-width: 1024px)';
$tablet: 'screen and (min-width: 569px) and (max-width: 1024px)';
$desktop: 'screen and (min-width: 1025px)';

:root {
    --default-margin: 23px;
    @include media-breakpoint-mobile() {
        --default-margin: 15px;
    }
    --skeleton-animation: skeletonAnimation 0.8s ease-in-out infinite alternate;
}

.hide-on-mobile {
    @media screen and (max-width: $sm-screen) {
        display: none;
    }
}

.hide-on-tablet {
    @media screen and (min-width: calc($sm-screen + 1px)) and (max-width: $lg-screen) {
        display: none;
    }
}

.hide-on-desktop {
    @media screen and (min-width: calc($lg-screen + 1px)) {
        display: none;
    }
}
