.Page {
    max-width: 1360px;
    margin: auto;
    padding: var(--default-margin);
    &--withSider {
        padding: 0;
        nz-sider,
        nz-content {
            padding: var(--default-margin);
        }
        nz-sider {
            width: 350px;
        }
        @include media-breakpoint-mobile {
            nz-sider {
                display: none;
            }
        }
    }
    &--noPaddingTop {
        padding-top: 0;
    }
}

// .Page {
//     display: flex;
//     width: 100%;
//     @media screen and (max-width: $xlg-screen) {
//         padding: 10px 2px 0 0;
//     }
//     @media screen and (max-width: $sm-screen) {
//         padding: 0;
//     }
// }
