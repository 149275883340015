.display-block {
    display: block;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.w-100 {
    width: 100%;
}

.w-500px {
    width: 500px;
}

.visibility-hidden {
    visibility: hidden;
}

.text-black {
    color: var(--black);
}

.text-small {
    font-size: 14px !important;
}

.text-white {
    color: white;
}

.text-red {
    color: var(--red);
}

.text-white {
    color: white;
}

.text-blue {
    color: var(--blue);
}

.text-pink {
    color: var(--pink);
}

.text-green {
    color: var(--green);
}

.text-dark-gray {
    color: var(--dark-grey);
}

.text-flat-green {
    color: var(--flat-green);
}

.text-flat-red {
    color: var(--flat-red);
}

.text-flat-yellow {
    color: var(--flat-yellow);
}

.text-bold {
    font-weight: bold;
}

.text-center {
    text-align: center !important;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-justify {
    text-align: justify;
}

.text-clickable {
    cursor: pointer;
    user-select: none;
}

.text-link {
    cursor: pointer;
    text-decoration: underline;
    font-size: 19px;
}

.text-uppercase {
    text-transform: uppercase;
}

.bg-grey-light {
    background-color: var(--light-grey);
}

.bg-light-pink {
    background-color: var(--light-pink);
}

.bg-red {
    background-color: var(--red) !important;
}

.bg-white {
    background-color: var(--white);
}

tr.bg-green td {
    background-color: var(--green);
}

tr.bg-red td {
    background-color: #ff7979 !important;
    color: white;
}

.nowrap {
    white-space: nowrap;
}

@for $i from 0 through 20 {
    .p-#{$i} {
        padding: 5px * $i;
    }
    .pt-#{$i} {
        padding-top: 5px * $i;
    }
    .pb-#{$i} {
        padding-bottom: 5px * $i;
    }
    .pl-#{$i} {
        padding-left: 5px * $i;
    }
    .pr-#{$i} {
        padding-right: 5px * $i;
    }
    .pv-#{$i} {
        padding-top: 5px * $i;
        padding-bottom: 5px * $i;
    }
    .ph-#{$i} {
        padding-left: 5px * $i;
        padding-right: 5px * $i;
    }

    .m-#{$i} {
        margin: 5px * $i;
    }
    .mt-#{$i} {
        margin-top: 5px * $i;
    }
    .mb-#{$i} {
        margin-bottom: 5px * $i;
    }
    .ml-#{$i} {
        margin-left: 5px * $i;
    }
    .mr-#{$i} {
        margin-right: 5px * $i;
    }
    .mv-#{$i} {
        margin-top: 5px * $i;
        margin-bottom: 5px * $i;
    }
    .mh-#{$i} {
        margin-left: 5px * $i;
        margin-right: 5px * $i;
    }
}

@for $i from 0 through 20 {
    .p-#{$i}px {
        padding: 1px * $i;
    }
    .pt-#{$i}px {
        padding-top: 1px * $i;
    }
    .pb-#{$i}px {
        padding-bottom: 1px * $i;
    }
    .pl-#{$i}px {
        padding-left: 1px * $i;
    }
    .pr-#{$i}px {
        padding-right: 1px * $i;
    }
    .pv-#{$i}px {
        padding-top: 1px * $i;
        padding-bottom: 1px * $i;
    }
    .ph-#{$i}px {
        padding-left: 1px * $i;
        padding-right: 1px * $i;
    }

    .m-#{$i}px {
        margin: 1px * $i;
    }
    .mt-#{$i}px {
        margin-top: 1px * $i;
    }
    .mb-#{$i}px {
        margin-bottom: 1px * $i;
    }
    .ml-#{$i}px {
        margin-left: 1px * $i;
    }
    .mr-#{$i}px {
        margin-right: 1px * $i;
    }
    .mv-#{$i}px {
        margin-top: 1px * $i;
        margin-bottom: 1px * $i;
    }
    .mh-#{$i}px {
        margin-left: 1px * $i;
        margin-right: 1px * $i;
    }
}
