@use 'sass:map';

$breakpoints: (
    // Extra small screen / phone
    xs: 480px,
    // Small screen / tablet
    sm: 576px,
    // Medium screen / desktop
    md: 768px,
    // Large screen / wide desktop
    lg: 992px,
    // Extra large screen / full hd
    xl: 1200px,
    // Extra extra large screen / large desktop
    xxl: 1600px
);

@mixin media-breakpoint-up($breakpoint) {
    @if map.has-key($breakpoints, $breakpoint) {
        @media (min-width: map.get($breakpoints, $breakpoint)) {
            @content;
        }
    } @else {
        @error 'Invalid breakpoint: #{$breakpoint}.';
    }
}

@mixin media-breakpoint-down($breakpoint) {
    @if map.has-key($breakpoints, $breakpoint) {
        @media (max-width: (map.get($breakpoints, $breakpoint) - 1)) {
            @content;
        }
    } @else {
        @error 'Invalid breakpoint: #{$breakpoint}.';
    }
}

@mixin media-breakpoint-between($a, $b) {
    @if map.has-key($breakpoints, $a) and map.has-key($breakpoints, $b) {
        @media (min-width: map.get($breakpoints, $a)) and (max-width: (map.get($breakpoints, $b) - 1)) {
            @content;
        }
    } @else {
        @error 'Invalid breakpoint: #{$a} or #{$b}.';
    }
}

@mixin media-breakpoint-mobile() {
    @include media-breakpoint-down(md) {
        @content;
    }
}

@mixin media-breakpoint-desktop() {
    @include media-breakpoint-up(md) {
        @content;
    }
}

.d-none {
    display: none;
}

.d-block {
    display: block;
}

.d-flex {
    display: flex;
}

.d-mobile-none {
    @include media-breakpoint-mobile() {
        display: none;
    }
}

.d-mobile-flex {
    @include media-breakpoint-mobile() {
        display: flex;
    }
}

.d-mobile-block {
    @include media-breakpoint-mobile() {
        display: block;
    }
}

.d-desktop-none {
    @include media-breakpoint-desktop() {
        display: none;
    }
}

.d-desktop-flex {
    @include media-breakpoint-desktop() {
        display: flex;
    }
}

.d-desktop-block {
    @include media-breakpoint-desktop() {
        display: block;
    }
}

@each $name, $breakpoint in $breakpoints {
    .d-up-#{$name}-none {
        @include media-breakpoint-up($name) {
            display: none;
        }
    }
    .d-down-#{$name}-none {
        @include media-breakpoint-down($name) {
            display: none;
        }
    }
    .d-up-#{$name}-block {
        @include media-breakpoint-up($name) {
            display: block;
        }
    }
    .d-down-#{$name}-block {
        @include media-breakpoint-down($name) {
            display: block;
        }
    }
    .d-up-#{$name}-flex {
        @include media-breakpoint-up($name) {
            display: flex;
        }
    }
    .d-down-#{$name}-flex {
        @include media-breakpoint-down($name) {
            display: flex;
        }
    }
    .d-up-#{$name}-inline-flex {
        @include media-breakpoint-up($name) {
            display: inline-flex;
        }
    }
    .d-down-#{$name}-inline-flex {
        @include media-breakpoint-down($name) {
            display: inline-flex;
        }
    }
}
