.CardTabs {
    &--noTabContent {
        .ant-tabs-tabpane {
            display: none;
        }
    }
    &.ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
        background-color: #fff;
        padding: var(--default-margin);
    }
    .ant-tabs-tab button {
        font-size: 14px;
        font-weight: 400;
        cursor: pointer;
        color: #000;
        a {
            color: #000;
        }
        span[nz-icon] {
            margin-right: 5px;
        }
    }
    &.ant-tabs > .ant-tabs-nav {
        margin: 0;
    }
    &.ant-tabs-card .ant-tabs-tab {
        border-color: transparent;
        // background: transparent;
    }
    &.ant-tabs-card .ant-tabs-tab-active {
        border-color: #fff;
        background: #fff;
        .ant-tabs-tab-btn {
            text-shadow: none;
        }
        a {
            color: var(--pink);
        }
    }
}
