@import 'src/assets/scss/responsive.scss';

@keyframes logo {
    from {
        transform: scale(0.28);
    }
    to {
        transform: scale(0.3);
    }
}

@keyframes circle {
    from {
        transform: scale(0.1);
        opacity: 0;
    }
    20% {
        opacity: 0.3;
    }
    to {
        transform: scale(1.2);
        opacity: 0;
    }
}

.Splash {
    --logo-width: 350px;
    @include media-breakpoint-down(md) {
        --logo-width: 50vw;
    }
    width: 100vw;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    background-color: var(--white);
    &-logo {
        position: relative;
        width: var(--logo-width);
        height: var(--logo-width);
        &-circle1,
        &-circle2 {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 50%;
            transform: scale(0);
            background-color: var(--pink);
        }
        &-circle1 {
            z-index: 1;
            animation: circle 1.4s linear infinite;
        }
        &-circle2 {
            z-index: 2;
            animation: circle 1.4s linear infinite 0.3s;
        }
        img {
            z-index: 1;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 3;
            width: 100%;
            height: 100%;
            transform: scale(0.4);
            animation: logo 0.8s ease-in-out infinite alternate;
        }
    }
}
