@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,600;1,700&display=swap');
@import 'responsive';
@import 'colors';
@import 'utilities';
@import 'typography';
@import 'variable';
@import 'modules';
@import '@angular/cdk/overlay-prebuilt.css';

body {
    font-family: 'Poppins', sans-serif;
}

p {
    color: var(--grey-dark);
    font-size: 19px;
    line-height: 30px;
    margin: 0;
    padding: 0;
}

.ModalBackdrop {
    &--default {
        background: rgba(0, 104, 255, 0.9);
    }
    &--transparent {
        background: transparent;
    }
    &--fullSize {
        overflow: hidden;
    }
}

.ant-layout-header {
    line-height: initial;
}

@keyframes skeletonAnimation {
    from {
        opacity: 1;
        // transform: scale(1);
    }
    to {
        opacity: 0.65;
        // transform: scale();
    }
}

.ant-table-tbody > tr > td {
    background-color: white;
}

nz-table td {
    font-size: 12px;
}

.lazyloaded-img {
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
    display: block;
    height: 0 !important;
    &--loaded {
        opacity: 1;
        height: inherit !important;
    }
}

.hide-scrollbar {
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.text-blue {
    color: var(--blue);
}

hr {
    width: 100%;
    border: 0;
    border-top: 1px solid var(--light-grey);
    &.noMargin {
        margin: 0;
    }
}

[nz-button] {
    &.--green {
        background-color: #27ae60;
        border-color: #27ae60;
        &:hover,
        &:active,
        &:focus,
        &:visited {
            background-color: #27ae60;
            border-color: #27ae60;
        }
    }
    &.--pink:not([nzType='dashed']) {
        background-color: var(--pink);
        border-color: var(--pink);
        color: white;
        &:hover,
        &:active,
        &:focus,
        &:visited {
            background-color: var(--pink);
            border-color: var(--pink);
        }
    }
    &.--pink[nzType='dashed'] {
        border-color: var(--pink);
        color: var(--pink);
        &:hover,
        &:active,
        &:focus,
        &:visited {
            border-color: var(--pink);
            color: var(--pink);
        }
    }
    &.--red {
        background-color: #e74c3c;
        border-color: #e74c3c;
        &:hover,
        &:active,
        &:focus,
        &:visited {
            background-color: #e74c3c;
            border-color: #e74c3c;
        }
    }
}

.ant-form-vertical .ant-form-item-label > label {
    width: 100%;
}

.ant-tabs-tab button {
    font-weight: 400;
}
