h1 {
    font-size: 20px;
    font-weight: 700;
}

h2 {
    font-size: 20px;
    font-weight: 400;
    padding-top: 30px;
    padding-bottom: 30px;
    &.--bold {
        font-weight: 600;
    }
}

h3 {
    font-size: 16px;
    font-weight: 700;
    padding-top: 20px;
    padding-bottom: 20px;
}
p {
    font-size: 14px;
    font-weight: 400;
    &.--small {
        font-size: 12px;
    }
    &.--large {
        font-size: 16px;
    }
}

button {
    font-size: 16px;
    font-weight: 700;
}
