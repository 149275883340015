:root {
    --pink: #ea1e7d;
    --light-pink: #ffedf3;
    --blue: #0068ff;
    --light-blue: #e8f1ff;
    --gradient-blue: linear-gradient(180deg, #0068ff 0%, #0146aa 100%);
    --black: #000000;
    --dark-grey: #777777;
    --light-grey: #f2f2f2;
    --white: #ffffff;
    --red: #ff0000;
    --green: #27ae60;

    /* Flats colors */
    --flat-green: #1abc9c;
    --flat-red: #e74c3c;
    --flat-yellow: #f1c40f;
}
