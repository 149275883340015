table.Table {
    margin: 0;
    padding: 0;
    // border-collapse: separate;
    // border-spacing: 0 var(--default-margin);
    thead {
        tr {
            background-color: var(--blue);
            th {
                color: var(--white);
                font-size: 14px;
                font-weight: 300;
                text-transform: uppercase;
                padding: 20px;
                padding-right: 0;
                text-align: left;
                &:last-child {
                    padding-right: 20px;
                }
            }
        }
    }
    tbody {
        tr {
            background-color: white;
            box-shadow: 0px 4px 15px rgba(#000, 0.06);
            td {
                padding: 20px;
                padding-right: 0;
                font-size: 12px;
                font-weight: 400;
                color: var(--black);
                vertical-align: top;
                strong {
                    font-weight: 600;
                }
                &:last-child {
                    padding-right: 20px;
                }
            }
        }
    }
}
// .ant-table-content {
//     overflow: auto;
// }
